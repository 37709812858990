import auth from '@/state/utils/auth'

export const namespaced = true

export const state = {
  currentUser: undefined,
}

export const getters = {
  getCurrentUser() {
    return state.currentUser
  },
  getCurrentUserName() {
    const { currentUser } = state
    const { firstName, lastName } = currentUser || {}
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return firstName || lastName
  },
  getCurrentUserInitials() {
    return state.currentUser
  },
}

export const mutations = {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser
  },
}

export const actions = {
  async init({ commit }) {
    const profile = auth.getProfile(true)
    if (profile && profile.user) {
      commit('setCurrentUser', profile.user)
    }
  },
  setCurrentUser({ commit }, currentUser) {
    commit('setCurrentUser', currentUser)
  },
  async isAuthenticated() {},
}
