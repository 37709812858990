import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#0cb0c8',
        // secondary: '#78C263',
        bobGreen: '#78C263',
        bobNavy: '#1a4e63',
        bobBlue: '#0cb0c8',
        bobOrange: '#f38a45',
        textGrey: '#333',
        // textGreyRGB: '51, 51, 51',
        labelGrey: '#5E5E5E',
        // labelGreyRGB: '94, 94, 94',
        alertRed: '#DD3C3C',
        borderGrey: '#EBEBEB',
        offWhite: '#F6F6F6',
        successGreen: '#6DD400',
        linkBlue: '#2CB7DF',
        bobPurple: '#BE5BBF',
      },
      dark: {
        primary: '#1a4e63',
        secondary: '#78C263',
      },
    },
  },
})
