import axios from 'axios'

export const namespaced = true

export const state = {
  symbols: [],
}

export const getters = {
  getSymbols() {
    return state.symbols
  },
}

export const mutations = {
  setSymbols(state, symbols) {
    state.symbols = symbols.map((s) => ({
      text: `${s.symbol} - ${s.name}`,
      value: s.symbol,
    }))
  },
}

export const actions = {
  async init({ commit }) {
    actions.getSymbols({ commit })
  },
  async getSymbols({ commit }) {
    const symbols = await axios
      .get('https://api.iextrading.com/1.0/ref-data/symbols')
      .catch(() => false)
    if (symbols.data) {
      commit('setSymbols', symbols.data)
    }
  },
}
