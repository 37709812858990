import axios from 'axios'

export const namespaced = true

export const state = {
  queryString: undefined,
  queryResults: {},
  showResultsPanel: false,
  loadingResults: false,
}

export const getters = {
  getQueryResults() {
    return state.queryResults
  },
  getQueryString() {
    return state.queryString
  },
  getLoadingResults() {
    return state.loadingResults
  },
  getShowResultsPanel() {
    return state.showResultsPanel
  },
}

export const mutations = {
  setQueryResults(state, queryResults) {
    state.queryResults = queryResults
  },
  setQueryString(state, queryString) {
    state.queryString = queryString
  },
  setShowResultsPanel(state, value) {
    state.showResultsPanel = value
  },
  setLoadingResults(state, value) {
    state.loadingResults = value
  },
}

export const actions = {
  async executeQuery({ commit }) {
    commit('setLoadingResults', true)
    const queryResultData = await axios
      .get(`/v0/global-search?q=${state.queryString}`)
      .catch((e) => e.response)
    if (queryResultData.data) {
      commit('setQueryResults', queryResultData.data)
    }
    commit('setLoadingResults', false)
  },
  setShowResultsPanel({ commit }, value) {
    commit('setShowResultsPanel', value)
  },
}
