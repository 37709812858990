import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import Vuemeta from 'vue-meta'
import router from './router'
import vuetify from './plugins/vuetify'
import { store } from './state/store'

const hostMap = {
  local: 'http://localhost:4070',
  test: 'https://test.letbob.com',
  prod: 'https://portal.letbob.com',
}

axios.defaults.baseURL = window.location.href.includes('localhost')
  ? hostMap['local']
  : undefined

Vue.config.productionTip = false

Vue.use(Vuemeta)

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
