export const namespaced = true

export const state = {
  showAdvisorProfileMenu: false,
}

export const getters = {
  getShowAdvisorProfileMenu() {
    return state.showAdvisorProfileMenu
  },
}

export const mutations = {
  setShowAdvisorProfileMenu(state, value) {
    state.showAdvisorProfileMenu = value
  },
}

export const actions = {
  setShowAdvisorProfileMenu({ commit }, value) {
    commit('setShowAdvisorProfileMenu', value)
  },
}
