import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/state/utils/auth'
import { store } from '@/state/store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import('@/views/AdvisorMain.vue'),
      meta: { public: true },
      children: [
        {
          path: '/dashboard',
          component: () => import('@/views/Dashboard.vue'),
          name: 'dashboard',
        },
        {
          path: '/advisor',
          component: () => import('@/views/RouterOutlet.vue'),
          name: 'advisor',
          children: [
            {
              path: 'models/:id',
              component: () => import('@/flows/advisor/ViewModel.vue'),
              name: 'viewModel',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager'],
              },
            },
            {
              path: 'clients/:id',
              component: () => import('@/flows/advisor/ViewClient.vue'),
              name: 'viewClient',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager'],
              },
            },
            {
              path: 'trade-review',
              component: () => import('@/flows/advisor/ViewTradeReview.vue'),
              name: 'tradeReview',
              meta: {
                disallowedRoles: [],
                allowedRoles: ['accountManager'],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/callback',
      component: () => import('@/views/Callback.vue'),
      name: 'authComplete',
      meta: { public: true },
    },
    {
      path: '/login-router',
      component: () => import('@/views/Callback.vue'),
      name: 'loginRouter',
      meta: { public: true },
    },
    {
      path: '/access-error',
      component: () => import('@/views/AccessError.vue'),
      name: 'accessError',
      meta: { public: true },
    },
    {
      path: '/logout',
      component: () => import('@/views/Dashboard.vue'),
      name: 'logout',
      meta: { public: true },
    },
  ],
})

router.beforeEach((routeTo, routeFrom, next) => {
  try {
    if (routeTo.path === '/login') {
      // REDIRECT TO LOGIN PORTAL
      return auth.login()
    }

    // AUTH CALLBACK
    if (routeTo.name === 'authComplete') {
      return next()
    }

    // REDIRECT TO PROPER LANDING PAGE BASED ON USER
    if (routeTo.name === 'loginRouter') {
      // TODO: User profile driver home page.
      return router.push('dashboard')
    }

    // LOGOUT
    if (routeTo.name === 'logout') {
      auth.logout()
      return next()
    }

    // NO MATCHING ROUTE
    // REDIRECT TO PROPER LANDING PAGE BASED ON USER
    if ((routeTo.matched || []).length === 0) {
      // TODO: User profile driver home page.
      return router.push('dashboard')
    }

    // CHECK EXPIRED SESSION
    if (!auth.isAuthenticated()) {
      return auth.login()
    }

    // CHECK ROUTE PERMISSIONS BEFORE ROUTING
    if (!router.hasPermission(routeTo)) {
      router.push('accessError')
    }

    // GO!!!
    next()
  } catch (e) {
    console.error('ROUTE ERROR', e)
  }
})

router.hasPermission = (route) => {
  const {
    public: publicRoute = false,
    disallowedRoles = [],
    allowedRoles = [],
  } = route.meta || {}
  if (publicRoute) {
    return true
  }
  const user = store.getters['user/getCurrentUser']
  const userProfile = user?.userProfile || []
  const roleCodes = userProfile.map((u) => u.roleCode)
  const disallowed = disallowedRoles.find((d) => roleCodes.includes(d))
  const allowed = allowedRoles.find((d) => roleCodes.includes(d))
  if (disallowedRoles.length && disallowed) {
    return false
  }
  if (allowedRoles.length) {
    if (allowed) return true
  } else {
    return true
  }
  return false
}

export default router
