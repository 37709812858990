import axios from 'axios'

export const namespaced = true

export const state = {
  model: undefined,
  loadingModel: false,
  modelWeight: 0,
  rebalanceModelPreview: undefined,
  previewingChanges: false,
  modelChanged: false,
}

export const getters = {
  getModel() {
    return state.model
  },
  getLoadingModel() {
    return state.loadingModel
  },
  getModelWeight() {
    return state.modelWeight
  },
  getRebalanceModelPreview() {
    return state.rebalanceModelPreview
  },
  getModelChanged() {
    return state.modelChanged
  },
  getPreviewingChanges() {
    return state.previewingChanges
  },
}

export const mutations = {
  setModel(state, model) {
    state.model = model
  },
  setLoadingModel(state, value) {
    state.loadingModel = value
  },
  addTickerToModel(state, symbol) {
    const stateModel = { ...state.model }
    const { symbols = [] } = stateModel
    stateModel.symbols = [...symbols, symbol]
    state.model = stateModel
    mutations.setModelWeight(state)
  },
  updateTickerInModel(state, symbol) {
    const stateModel = { ...state.model }
    const { symbols = [] } = stateModel
    symbol.allocation = symbol.allocationPercent / 100 || 0
    delete symbol.allocationPercent
    stateModel.symbols = [
      ...symbols.filter((s) => s.symbol !== symbol.symbol),
      symbol,
    ]
    state.model = stateModel
    mutations.setModelWeight(state)
  },
  setModelWeight(state) {
    state.modelWeight = +Number(
      (state?.model?.symbols || [])
        .map((t) => +t.allocation || 0)
        .reduce((a, b) => a + b, 0) * 100
    ).toFixed(0)
  },
  setRebalanceModelPreview(state, rebalanceModelPreview) {
    state.rebalanceModelPreview = rebalanceModelPreview
  },
  setModelChanged(state, value) {
    state.modelChanged = value
  },
  setPreviewingChanges(state, value) {
    state.previewingChanges = value
  },
}

export const actions = {
  async getModelDetail({ commit, dispatch }, modelId) {
    commit('setLoadingModel', true)
    const modelData = await axios
      .get(`/v0/view-model/${modelId}`)
      .catch((e) => e.response)
    if (modelData?.data) {
      commit('setModel', modelData.data)
    }
    commit('setModelChanged', false)
    commit('setLoadingModel', false)
    commit('setModelWeight')
    dispatch('cancelPreviewChanges')
  },
  async previewModelWeightUpdates({ commit }, model) {
    commit('setLoadingModel', true)
    const { symbols = [] } = model
    const defaultFlags = {
      exitOnly: false,
      roundUpShares: false,
    }
    const updatedSymbols = symbols.map((s) => ({ ...s, ...defaultFlags }))
    const updatedModel = {
      ...model,
      symbols: updatedSymbols,
    }
    const rebalanceModelPreview = await axios
      .put(`/v0/model/rebalance-model/${model._id}?preview=true`, updatedModel)
      .catch((e) => e.response)
    if (rebalanceModelPreview.data) {
      commit('setModel', updatedModel)
      commit('setRebalanceModelPreview', rebalanceModelPreview.data)
    }
    commit('setPreviewingChanges', true)
    commit('setLoadingModel', false)
  },
  async updateModelWeights({ commit, dispatch }, model) {
    commit('setLoadingModel', true)
    await axios
      .put(`/v0/model/rebalance-model/${model._id}`, model)
      .catch((e) => e.response)
    commit('setLoadingModel', false)
    dispatch('getModelDetail', model._id)
  },
  async addTickerToModel({ commit }, symbol) {
    commit('setModelChanged', true)
    commit('addTickerToModel', symbol)
  },
  async updateTickerInModel({ commit }, symbol) {
    commit('setModelChanged', true)
    commit('updateTickerInModel', symbol)
  },
  async cancelPreviewChanges({ commit }) {
    commit('setPreviewingChanges', false)
    commit('setRebalanceModelPreview', undefined)
  },
  cancelModelChanges({ dispatch }, modelId) {
    dispatch('getModelDetail', modelId)
  },
}
