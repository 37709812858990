import axios from 'axios'

export const namespaced = true

export const state = {
  tradeReview: [],
  loadingTradeReview: false,
}

export const getters = {
  getTradeReview() {
    return state.tradeReview
  },
  getLoadingTradeReview() {
    return state.loadingTradeReview
  },
}

export const mutations = {
  setTradeReview(state, tradeReview) {
    state.tradeReview = tradeReview
  },
  setLoadingTradeReview(state, value) {
    state.loadingTradeReview = value
  },
}

export const actions = {
  async getTradeReview({ commit }) {
    commit('setLoadingTradeReview', true)
    const tradeReviewData = await axios
      .get(`/v0/trade-review`)
      .catch((e) => e.response)
    if (tradeReviewData.data) {
      commit('setTradeReview', tradeReviewData.data)
    }
    commit('setLoadingTradeReview', false)
  },
  async getTradeReviewDetail(store, tradeReviewId) {
    const tradeReviewData = await axios
      .get(`/v0/trade-review/detail/${tradeReviewId}`)
      .catch((e) => e.response)
    if (tradeReviewData.data) {
      return tradeReviewData.data
    }
  },
  async confirmTrades({ commit, dispatch }, trades) {
    commit('setLoadingTradeReview', true)
    await axios.put(`/v0/trade-review/confirm`, trades).catch((e) => e.response)
    commit('setLoadingTradeReview', false)
    dispatch('getTradeReview')
  },
}
